// frontend/src/telegramWebApp.js
import axios from 'axios';

export async function initTelegramWebApp() {
  const urlParams = new URLSearchParams(window.location.search);
  const initData = urlParams.get('tgWebAppData');

  if (!initData) {
    console.error('No Telegram Web App data found');
    return;
  }

  const data = JSON.parse(decodeURIComponent(initData));

  try {
    const response = await axios.post('/api/telegram/init', data);
    if (response.data.status === 'ok') {
      // Initialization successful
      console.log('User data saved successfully');
      // You can now proceed with your app logic
    } else {
      console.error('Initialization failed');
    }
  } catch (error) {
    console.error('Initialization error:', error);
  }
}
