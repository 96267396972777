// src/components/Home.js
import React from 'react';
import { useSelector } from 'react-redux';
import './Home.css';

function Home() {
  const user = useSelector((state) => state.user);

  const handleConnectSteam = () => {
    window.location.href = '/auth/steam';
  };

  // Function to truncate nickname if longer than 25 characters
  const truncateNickname = (nickname) => {
    return nickname.length > 25 ? nickname.substring(0, 25) + '...' : nickname;
  };

  return (
    <div className="home-content">
      {/* Connect Wallet Button */}
      <div className="connect-button">
        <svg
          height="24"
          viewBox="0 0 512 512"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M47.5,104H432V51.52a16,16,0,0,0-19.14-15.69l-368,60.48a16,16,0,0,0-12,10.47A39.69,39.69,0,0,1,47.5,104Z"
            fill="black"
          />
          <path
            d="M463.5,128H47.5a16,16,0,0,0-16,16V432a16,16,0,0,0,16,16h416a16,16,0,0,0,16-16V144A16,16,0,0,0,463.5,128ZM368,320a32,32,0,1,1,32-32A32,32,0,0,1,368,320Z"
            fill="black"
          />
          <path
            d="M31.33,259.5V116c0-12.33,5.72-18.48,15.42-20,35.2-5.53,108.58-8.5,108.58-8.5s-8.33,16-27.33,16V128c18.5,0,31.33,23.5,31.33,23.5L84.83,236Z"
            fill="black"
          />
        </svg>
        <span>Connect Wallet</span>
      </div>

      {/* Logo */}
      <div className="logo">
        <svg viewBox="0 0 496 512" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M496 256c0 137-111.2 248-248.4 248-113.8 0-209.6-76.3-239-180.4l95.2 39.3c6.4 32.1 34.9 56.4 68.9 56.4 39.2 0 71.9-32.4 70.2-73.5l84.5-60.2c52.1 1.3 95.8-40.9 95.8-93.5 0-51.6-42-93.5-93.7-93.5s-93.7 42-93.7 93.5v1.2L176.6 279c-15.5-.9-30.7 3.4-43.5 12.1L0 236.1C10.2 108.4 117.1 8 247.6 8 384.8 8 496 119 496 256zM155.7 384.3l-30.5-12.6a52.79 52.79 0 0 0 27.2 25.8c26.9 11.2 57.8-1.6 69-28.4 5.4-13 5.5-27.3.1-40.3-5.4-13-15.5-23.2-28.5-28.6-12.9-5.4-26.7-5.2-38.9-.6l31.5 13c19.8 8.2 29.2 30.9 20.9 50.7-8.3 19.9-31 29.2-50.8 21zm173.8-129.9c-34.4 0-62.4-28-62.4-62.3s28-62.3 62.4-62.3 62.4 28 62.4 62.3-27.9 62.3-62.4 62.3zm.1-15.6c25.9 0 46.9-21 46.9-46.8 0-25.9-21-46.8-46.9-46.8s-46.9 21-46.9 46.8c.1 25.8 21.1 46.8 46.9 46.8z"
            fill="white"
          />
        </svg>
      </div>

      {/* Coins Display */}
      {user && user.steamConnected && (
        <div className="coins-display">
          <h1 className="user-coins-count">
            {user.coins}
            <svg height="24" viewBox="0 0 24 24" width="24">
              <path
                fill="white"
                d="M8.21 17.32 7 16.8a2.13 2.13 0 1 0 1.17-2.93l1.28.53a1.58 1.58 0 0 1-1.22 2.92z"
              />
              <path
                fill="white"
                d="M12 2a10 10 0 0 0-10 9.34l5.38 2.21a2.31 2.31 0 0 1 .47-.24A2.62 2.62 0 0 1 9 13.1l2.44-3.56a3.8 3.8 0 1 1 3.8 3.8h-.08l-3.51 2.5a2.77 2.77 0 0 1-5.47.68l-3.77-1.6A10 10 0 1 0 12 2z"
              />
              <path
                fill="white"
                d="M17.79 9.5a2.53 2.53 0 1 0-2.53 2.5 2.54 2.54 0 0 0 2.53-2.5zm-4.42 0a1.9 1.9 0 1 1 1.9 1.91 1.9 1.9 0 0 1-1.9-1.92z"
              />
            </svg>
          </h1>
        </div>
      )}

      {/* Show Steam Data if Connected */}
      {user && user.steamConnected ? (
        <div className="user-profile">
          <img src={user.photos[2]} alt="User Avatar" />
          <div className="user-info">
            <h2>{truncateNickname(user.displayName)}</h2>
            {user.totalHoursPlayed !== undefined && (
              <p>Total Hours Played: {user.totalHoursPlayed}</p>
            )}
          </div>
        </div>
      ) : (
        // Show "Connect Steam" button if Steam not connected
        <button className="connect-steam" onClick={handleConnectSteam}>
          Connect Steam
        </button>
      )}
    </div>
  );
}

export default Home;
